/* ============== common ============== */
@layer tailwind-base, antd;

@layer tailwind-base {
  @tailwind base;
}
@tailwind components;
@tailwind utilities;
/* =================================== */

body {
  font-family: 'Noto Sans', sans-serif;
}
